.AccountsUserRow {
  td {
    max-width: 0;
    width: auto;
    color: var(--gray-800);
    
    &:last-of-type {
      padding: 8px 0;
      white-space: nowrap;
      vertical-align: top;
    }
    p,
    strong {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }

    strong {
      display: block;
      margin-bottom: 0;
    }
  }

  .rolesContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
  }

  &__button{
    >div{
      display: flex;
      justify-content: flex-end;
    }
  }

  &__id{
    display: flex;
    gap: .25rem;
    color: var(--gray-600);
    p{
      width: auto!important;

    }
  }
}

.base-page {
  height: 100vh;
  background: var(--baseBackground, #120e2c);
  position: relative;
  overflow: hidden;

  &_bg {
    position: absolute;
    left: 60%;
    transform: translateX(-50%);
    top: -10%;
    height: 110%;
    max-width: 80%;
  }

  &_formarea {
    background: #ffffff;
    border-radius: 16px;
    padding: 40px;
    position: relative;
    top: 50%;
    z-index: 1;
    left: 50%;
    transform: translateY(-50%);
    width: 467px;

    &.phone-verification-area {
      width: 515px;
    }

    form {
      .form-row {
        margin: 0;
        margin-bottom: 24px;
        position: relative;
        label {
          margin-bottom: 3px;
        }

        .invalid-feedback {
          display: block;
          position: relative;
        }

        &.with-error {
          margin-bottom: 2px;
        }

        &.forgot-password-email {
          margin-bottom: 47px;

          &.with-error {
            margin-bottom: 25px;
          }
        }
        .phone-container {
          width: 100%;
        }
        &.send-code-row {
          .invalid-feedback {
            margin-top: 8px;
          }

          &.resend-code-buttons {
            margin-bottom: 40px;
            span {
              color: var(--primary);
              cursor: pointer;
              text-decoration: underline;
              margin-right: 24px;
            }
          }
        }
      }
      .password-row {
        position: relative;
        svg {
          color: #c3c6c9;
          position: absolute;
          right: 8px;
          top: 32px;
          cursor: pointer;
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;

      &.flex-end {
        justify-content: flex-end;
        flex-direction: row;

        button {
          width: auto;
        }
      }

      &.center {
        justify-content: center;
        flex-direction: row;

        button {
          width: auto;
        }
      }

      .invalid-feedback {
        display: block;
      }
      button {
        width: 100%;
        margin-top: 16px;
      }

      .to-other-login {
        margin-top: 56px;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        &-buttons {
          display: flex;
          width: 100%;
          gap: 24px;
        }

        p {
          margin: 0;
        }
        button {
          margin-top: 16px !important;
          //width: 180px;
          display: flex;
          flex-grow: 1;
          align-items: center;
          white-space: nowrap;
          svg {
            color: var(--primary);
            margin-right: 6px;
          }
        }
      }

      &.login-buttons {
        button {
          margin-top: 39px;
        }
        flex-direction: column;
      }

      &.reset-password-buttons {
        button {
          margin-top: 32px;
        }

        &.button-with-auto {
          justify-content: center;

          button {
            width: auto;
          }
        }
      }

      &.forgot-password-buttons {
        button {
          margin-top: 0;
        }
      }

      &.phone-verification-buttons {
        justify-content: flex-end;
        flex-direction: row;
        gap: 24px;

        button {
          width: auto;
          margin-top: 52px;
          svg {
            margin-right: 6px;
            color: var(--primary);
          }
        }

        &.step-2 {
          button {
            margin-top: 0px;
          }
        }

        &.resend-sms-exceeded {
          button {
            margin-top: 6px;
          }
        }
        &.login-page {
          gap: 0;
          flex-direction: column;
          button {
            width: 100%;
            margin-top: 24px;
          }
        }
      }
    }

    &-head {
      position: relative;
      margin-bottom: 24px;
      display: flex;

      justify-content: center;

      &.text-left {
        justify-content: flex-start;
      }

      h1,
      h2 {
        text-align: center;
      }

      &.register-form-head {
        justify-content: space-between;
        text-align: left;
      }

      &.forgot-password-head {
        margin-bottom: 32px;
      }

      &.phone-verification-head {
        display: block;
        margin-bottom: 16px;
        h1 {
          text-align: left;
          font-size: 20px;
          line-height: 24px;
        }

        &.resend-sms-exceeded {
          h1 {
            margin-bottom: 24px;
          }
        }

        .phone-verification-head-phone {
          margin-top: 8px;
          margin-bottom: 24px;
          span {
            margin-right: 8px;

            &:last-child {
              color: var(--primary);
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }

      .countrySelect {
        width: 170px;

        & > div {
          border: none;
        }
      }
      .invalid-feedback {
        display: block;
        margin-left: 8px;
      }
    }
    &-footer {
      margin-top: 64px;
      text-align: center;
      &.register,
      &.login {
        margin-top: 40px;
      }
    }
    &-errors {
      margin-bottom: 24px;
    }
    .forgot-password-link {
      margin-top: 8px;
      text-align: right;
      width: 100%;
      position: absolute;
      top: 61px;

      a {
        position: relative;
        z-index: 1;
      }
    }

    .oauth-error {
      span > span,
      a {
        white-space: nowrap;
      }
    }
  }
  &_copyright {
    position: absolute;
    left: 40px;
    bottom: 20px;
    color: #ffffff;
    p {
      margin: 0;
    }
  }
  &_logos {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 150px;
    &-logo {
      margin-right: 24px;
      img {
        width: 38px;
      }
    }
  }

  .app-logo {
    margin-bottom: 32px;
  }

  .app-link {
    display: flex;
    width: fit-content;
  }

  .app-title {
    h1 {
      font-weight: 500;
      font-size: 48px;
      line-height: 32px;
      color: #ffffff;
      margin-bottom: 40px;
    }
    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
    img {
      max-width: 100%;
    }
    &.general-logo {
      h1 {
        line-height: 3rem;
        margin-bottom: 1rem;
      }
      h2 {
        color: var(--grey-300);
        font-weight: normal;
        font-size: 1.5rem;
      }
    }
    &.copilot {
      h1 {
        line-height: 3rem;
        margin-bottom: 1rem;
      }
      h2 {
        color: var(--grey-300);
        font-weight: normal;
        font-size: 1.5rem;
      }
    }
    .cloud-title {
      h1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 32px;
        color: #ffffff;
        opacity: 0.4;
        margin-bottom: 29px;
      }
      h2 {
        font-weight: 500;
        font-size: 48px;
        line-height: 48px;
        color: #ffffff;
      }
    }
    &_immers {
      @extend .app-title;
      margin-top: -8px;
      width: fit-content;

      @media (max-width: 560px) {
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 8px;
        text-wrap: nowrap;
      }

      h2 {
        font-size: 24px;
        line-height: 29px;
        font-weight: 400;
        color: var(--gray-300);
      }
      h1 {
        font-size: 48px;
        font-weight: 500;
        line-height: 56.25px;
        margin: 0;
      }
    }
  }
  &_left-block {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 11%;

    &.not-part-of-cc {
      padding-bottom: 150px;
    }
  }
  &_email-verification {
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }
    h3 {
      margin-bottom: 32px;

      span {
        white-space: nowrap;
      }
    }
    &-buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 133px;
    }
  }
}

.captcha {
  & > div {
    bottom: 2px !important;
  }
  transform: none !important;
  * {
    transform: none !important;
  }
}

.crisp-client {
  display: none;
  &.visible {
    display: block;
  }
}

.countrySelect-accept {
  padding: 4px 8px 6px;
  width: 154px;
  color: #ffffff;
  position: absolute;
  z-index: 1;
  top: calc(100% + 3px);
  right: 3px;
  div {
    small {
      margin-right: 8px;
      border-bottom: 1px solid;
      cursor: pointer;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    top: -6px;
  }
}

.universal-login-pixels {
  font-size: 0;
}

.digit-list {
  caret-color: transparent;
}

.immers-info {
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  gap: 16px;
  max-width: 470px;
  a {
    color: #73a0ff !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }
}

.immers-info__text_sm,
.immers-info__text {
  font-weight: 400;
  line-height: 30px;
  color: white;
  margin: 0;
  b {
    font-size: inherit;
  }
}
.immers-info__text {
  font-size: 20px;
}
.immers-info__text_sm {
  font-size: 16px;
}

.SelectAccount {
  &__item {
    padding: 8px 14px;
    border-radius: 4px;
    border: 1px solid var(--gray-300);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;


  }

  &__data {
    overflow: hidden;
    width: 100%;
  }

  &__name {
    &.disabled {
      color: var(--gray-500);
    }
  }

  &__role {
    padding: 2px 4px;
    border-radius: 2px;
    background-color: var(--primary-200);

    &.disabled {
      opacity: 0.3;
      color: var(--gray-800);
      background-color: var(--gray-200);
    }
  }

  &__footer {
    padding-top: 34px;
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  &__invites {
    color: #741902;
    padding: 2px 4px;
    background-color: #fddac8;
    border-radius: 2px;
  }

  &__placeholder {
    background-color: var(--gray-100);
    border: 1px solid var(--gray-300);

    padding: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__list{
    padding-left: 16px;
  }
}

.header {
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(13, 24, 34, 0.08);
  padding: 12px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo{
    img{
      height: 24px;
    }
  }

  &-menu-wrap {
    display: flex;
    gap: 32px;
    .justui_dropdown {
      .dropdown-menu.show {
        right: 0;
        left: auto !important;
        transform: translate3d(0, 40px, 0px) !important;
        min-width: fit-content;
      }
      & > button {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 50%;

        &:hover {
          background-color: var(--gray-100);
        }
        &:active {
          background-color: var(--gray-200);
        }

        &:focus {
          border: none;
          box-shadow: none;
        }
      }
      .user-menu-button {
        width: 40px;
        height: 40px;
        background-color: var(--gray-100);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;

        &:hover,
        &:active {
          background-color: var(--gray-200);
        }
      }
      .product-dropdown {
        .dropdown-item {
          padding: 0;
        }
      }
      .dropdown-item{
        padding: 10px 1rem;
      }
    }
  }

  .menu-button {
    cursor: pointer;
  }
}

@media (max-width: 560px) {
  .header {
    .logo {
      width: 22px;
      overflow: hidden;
    }
  }
}

.ResponseField {
  &__wrapper {
    padding: 0.5rem;
    border-radius: 0.25rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: -1rem;
  }

  &__limit {
    font-weight: 700;
  }

  &__list {
    padding-left: 20px;
    margin-bottom: 0;
  }
}

.error,
.limit {
  background-color: var(--red-200);
}
.info {
  background-color: var(--blue-200);
}

.AddUsersTable {
  color: var(--gray-800);
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0 !important;

  thead {
    position: relative;
  }

  tr th {
    vertical-align: middle;
    white-space: nowrap;
  }

  th,
  td {
    padding: 1rem 0.5rem;
  }
  th {
    border-top: 0 !important;
    border-bottom-width: 1px !important;
  }
}

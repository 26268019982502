.Modal{
    :global(.modal-content){
        border-radius: 1rem;
    }
    :global(.close){
        padding:0!important;
        margin: 0!important;
        line-height: 1.25rem !important;
        
    }
}
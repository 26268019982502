.GroupedSelector {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .multilineSelect {
    :global(.just-select-container) {
      padding-top: 4px;
    }
    :global(input.multiple),
    :global(.just-select-selected-item) {
      margin-bottom: 4px;
      border: none;
    }
  }

  input {
    background-color: transparent !important;
  }
  .actionButton {
    align-self: flex-start;
    margin-left: 2px;
    margin-bottom: 2px;
  }
}

.InvitePage {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__wrapper {
    width: 31.25rem;
  }

  &__button {
    align-self: flex-end;
  }
}

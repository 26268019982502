.Pagination {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  align-items: center;
  align-self: flex-end;

  &_paginator {
    margin-bottom: 0;
    padding: 0;
    display: flex;
    align-items: center;

    & > * {
      display: flex;
    }

    .Pagination_page,
    .Pagination_previous,
    .Pagination_next {
      height: 24px;
      min-width: 23px;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 23px;

      &:active {
        outline: none;
      }

      &.disabled {
        cursor: not-allowed;
        path {
          fill: #ccc;
        }
      }

      &_link {
        width: 100%;
        height: 100%;
        padding: 0 8px;
        text-align: center;
        display: block;
        line-height: 23px;
      }

      &.Pagination_active {
        background-color: var(--gray-100);
        border-radius: 1px;
      }
    }
  }

  & .goToPageContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
    gap: 8px;

    & input {
      height: 34px;
      max-width: 3rem;
    }
  }

  & .sizeContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;

    &__select {
      width: 75px;
    }
  }
}

.SizeDropdownSelect :global(.scrollbar-container) {
  padding: 0;
}

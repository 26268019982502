.InnerPage {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 24px;
  }

  &__content {
    border-radius: 8px;
    background: var(--white, #fff);
    box-shadow: 0 0 1px 0 rgba(17, 60, 92, 0.06), 0 1px 3px 0 rgba(12, 43, 66, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.16);
    padding: 24px;
  }

  &__fixed{
    max-width: 1280px;
  }

  &__rightSlot{
    flex-shrink: 0;
  }
}

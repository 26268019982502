.InviteToProductForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__info {
    padding: 0.5rem 1rem;
    background-color: var(--blue-50);
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    p:last-of-type {
      font-weight: 700;
    }
  }

  &__docs {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;

    a {
      color: var(--primary);
      text-decoration: none;
    }
  }

  &__wrapper {
    max-width: 600px;
  }

  &__input {
    > div {
      width: 247px !important;
    }

    svg {
      color: var(--gray);
    }
  }

  &__button {
    align-self: flex-end;
  }

  &__limit {
    display: flex;
    display: flex;
    justify-content: space-between;

    margin-top: 0.25rem;
    > div {
      color: var(--gray-600);

      display: flex;
      flex-direction: column;
    }
  }

  &__emails {
    &__input {
      &__error {
        color: var(--danger);
      }
    }

    &__tagWrapper {
      display: flex;
      gap: 0.25rem;
    }

    &__error {
      background-color: var(--red-200);
      color: var(--red-800);
    }
    &__dups {
      background-color: var(--blue-200);
      color: var(--blue-800);
    }
  }
}

.footer {
  background-color: #fff;
  padding: 8px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-950);
  flex-wrap: wrap;
  gap: 32px;

  &_links {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    a {
      text-decoration: none;
      color: inherit;
      display: flex;
      align-items: center;

      svg {
        margin-left: 8px;
        color: var(--gray-800) !important;
      }

      span {
        text-decoration: underline;
      }

      &:hover {
        color: inherit;

        span {
          text-decoration: none;
        }
      }
    }
  }
}

.ScrollShadowDiv {
  &__wrapper {
    position: relative;
    overflow-y: auto;
    max-height: 400px;

    scrollbar-gutter: stable;
    &::-webkit-scrollbar {
      position: relative;
      width: 16px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 10px;
      background-clip: padding-box;
      border-right: 5px solid transparent;
      border-top: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 5px solid transparent;
      border-radius: 6px;
      background-color: var(--gray-200);
      cursor: pointer;
    }
  }

  &__top {
    position: sticky;
    top: 0;
    margin-bottom: -1rem;
    width: 100%;
    height: 1rem;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-duration: 300ms;
    background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.13), transparent),
      linear-gradient(180deg, hsla(0, 0%, 100%, 0) 40%, hsla(0, 0%, 100%, 0));
  }

  &__bottom {
    position: sticky;
    bottom: 0;
    margin-top: -1rem;
    width: 100%;
    height: 1rem;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-duration: 300ms;
    transform: rotate(180deg);
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 40%, hsla(0, 0%, 100%, 0)),
      radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.13), transparent);
  }

  &__opacity {
    opacity: 1;
  }
  &__noOpacity {
    opacity: 0;
  }
}

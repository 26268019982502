.Full {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  &__content {
    width: 100%;
    display: grid;
    flex: 1;
    overflow-y: auto;
  }
}
.AddUsersRow {
  td {
    width: auto;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 0;
    }

    &.checkboxRow {
      width: 2%;
    }
    &.idRow {
      width: 10%;
    }

    &.emailRow {
      max-width: 0;
      width: 30%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.nameRow {
      max-width: 0;
      width: 30%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.accountsRow {
      width: 30%;
      p {
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        white-space: break-spaces !important;
      }
    }
  }

  & .secondary {
    color: var(--gray-600);
  }
}

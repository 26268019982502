.AddUserPage {
  &__pagination {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding-top: 0.5rem;
    border-top: 1px solid var(--gray-100);
  }

  &__footer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    p {
      color: var(--gray-600);
      padding: 0.125rem 0.25rem;

      &:last-child {
        margin-left: -0.5rem;
      }
    }
  }

  &__selectedUser {
    padding: 0.125rem 0.25rem;
    background-color: var(--gray-200);
    border-radius: 0.125rem;
    line-height: 1.25rem;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }

  &__submit {
    align-self: flex-start;
  }
}

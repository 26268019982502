.TagInput {
  border: 1px solid var(--gray-200);
  border-radius: 3px;
  overflow: hidden;
  padding: 6px 12px;
  min-height: 80px;
  > span:first-child {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }

  &__input {
    background: transparent;
    border: 0;
    outline: none;
    min-width: 80px;
    flex: 1;
  }

  &__tag {
    padding: 2px 4px;
    background-color: var(--gray-200);
    border-radius: 4px;
    svg {
      cursor: pointer;
    }
  }
}

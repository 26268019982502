.sidebar-wrapper {
  height: 100%;

  .sidebar {
    padding: 24px 16px;
    width: 216px;
    position: sticky;
    top: 0;

    .sidebar-item {
      position: relative;

      a {
        display: inline-flex;
        padding: 8px 24px 8px 16px;
        color: var(--gray-600);
        text-decoration: none;
        border-radius: 8px;
        width: 100%;
        &:hover {
          background-color: var(--gray-50);
        }
        &:active {
          background-color: var(--gray-100);
        }
        &.active {
          color: var(--gray-950);
          font-weight: 700;
        }
      }

      & .notification-mark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        background-color: var(--primary-600);
        border-radius: 50%;
        height: 8px;
        width: 8px;
      }
    }
  }
}

@import "~@just-ai/just-ui/scss/basic/custom/functions";
@import "~@just-ai/just-ui/scss/basic/custom/variables";

// TODO: check colors

.digit {
  display: inline-block;
  width: 35px;
  height: 38px;
  margin: 0 5px;
  padding: 6px 12px;
  border: 1px solid $greyblue;
  border-radius: 3px;
}

.digit::placeholder {
  font-size: 14px;
  line-height: 24px;
}

.digit:focus {
  border-color: #1E7899;
  outline: 0;

  @if $enable-shadows {
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
  } @else {
    box-shadow: $input-focus-box-shadow;
  }
}

.digit_error {
  border-color: $danger;
}

input::-webkit-inner-spin-button {
  display: none;
}
